import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/pod-logo.svg';
import icons from '../../images/pod-icons.svg';
import axios from 'axios';
import './index.css';

const Landing = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [values, setValues] = useState({
    name: '',
    email: '',
  });
  const apiURL = process.env.REACT_APP_API_URL;

  const handleRegister = async () => {
    try {
      const response = await axios.post(`${apiURL}/api/lead`, {
        name: values.name,
        email: values.email,
      });
      console.log(response);
      setValues({ name: '', email: '' });
      navigate('/welcome');
    } catch (error: any) {
      setError(error.response.data.error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <div className='landingContainer'>
      <div className='landingContent'>
        <p className='landingTitle'>Going somewhere with space to spare?</p>
        <img className='podLogo' src={logo} alt='logo' />
        <p className='landingSubtitle'>The journey earning app.</p>
        <img className='podIcons' src={icons} alt='icons' />
        <p className='landingText'>
          POD enables greener, more personalised deliveries for everyone, by connecting them with
          drivers on their everyday journeys. And it’s coming soon.
        </p>
      </div>
      <div className='landingForm'>
        <p className='landingSubtitle'>Calling all Drivers. Become a founding member!</p>
        <p className='landingText'>
          Our first 1,000 sign-ups will benefit from <b>free membership for life</b> and will be
          invited to <b>join POD before we launch</b> to the wider public.
        </p>
        <p className='landingText'>
          Simply enter your name and email below and we will be in
          touch!
        </p>
        <input
          className='landingInput'
          type='text'
          name='name'
          placeholder='Name'
          value={values.name}
          onChange={handleChange}
        />
        <input
          className='landingInput'
          type='email'
          name='email'
          placeholder='Email'
          value={values.email}
          onChange={handleChange}
        />
        <button className='landingButton' onClick={handleRegister}>
          REGISTER YOUR INTEREST
        </button>
        {error && <div className='error'>{error}</div>}
      </div>
    </div>
  );
};

export default Landing;
