import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  XIcon,
  TwitterShareButton,
} from 'react-share';
import carIcon from '../../images/car-icon.png';
import './index.css';

const Welcome = () => {
  const shareUrl = 'https://www.podteam.co.uk';
  return (
    <div className='welcomeContainer'>
      <div className='welcomeContent'>
        <img className='carIcon' src={carIcon} alt='Car Icon' />
        <p className='welcomeTitle'>Great to have you on board.</p>
        <p className='welcomeSubtitle'>
          Sit tight, you are now onboard. Stay tuned for details on our launch,
          and your exclusive early access instructions.
        </p>
        <button className='shareButton'>Share POD with everyone!</button>
        <div className='shareMediaContainer'>
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <LinkedinShareButton url={shareUrl}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <WhatsappShareButton url={shareUrl}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TwitterShareButton url={shareUrl}>
            <XIcon size={32} round />
          </TwitterShareButton>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
